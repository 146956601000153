/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Box, Menu, MenuButton, MenuList, MenuItem, Avatar, Text, Image, Button } from "@chakra-ui/react";
import User from "../../helpers/current-user";
import styled from "@emotion/styled";
import bell from "../../assets/images/icons/BellSimple.png";
import { IoIosArrowDown } from "react-icons/io";
import DGShipping from "../../assets/images/logo/dg_shipping_logo_new.png";
import { colors } from "../../helpers/theme";
const Header = () => {
	const HeaderWrapper = styled(Box)`
		position: fixed;
		top: 0;
		left: 0;
		background: ${colors.white[900]};
		right: 0;
		z-index: 2;
		height: 60px;
	`;
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const user = User.get();
	const [notificationList] = useState([]);
	useEffect(() => {
		if (User.loggedin()) {
			setIsLoggedIn(true);
		}
	}, []);

	return isLoggedIn ? (
		<HeaderWrapper
			boxShadow="md"
			d="flex"
			justifyContent="end"
			alignItems="center"
			w={{ base: "100vw", lg: "82%" }}
			ml={{ base: "0", lg: "18%" }}
			p={3}
		>
			<Box d="flex" p={2} boxShadow="md" border="1px solid gray" borderRadius="md" mx={3} hidden>
				<Menu>
					<MenuButton
						px={2}
						d={{ xs: "none", md: "inline-block" }}
						backgroundColor="transparent"
						border={"none"}
					>
						<Image src={bell} w="15px" h="15px" />
					</MenuButton>
					{user.is_sic ?
						<MenuList>
							<Box key={"index"} flexDirection="column" pt={1} overflowY="auto" pl={2}>
								<Box>
									<Text color="gray.700" fontSize="md" fontWeight="bold" pl={3} pb={2}>
										Project Access Request
									</Text>
								</Box>
								{
									notificationList?.length > 0 ? notificationList?.map((item, index) => (
										<Box alignItems="center" key={index} d="flex" justifyContent="space-between" p={1} >
											<Box >
												<Avatar h="25px" w="25px" size="sm" name={item.employee_name} src="https://bit.ly/broken-link" />
											</Box>
											<Box d="flex" w={"70%"}>
												{item.employee_name} has requested access for {item.project_name}
											</Box>
											<Box d="flex">
												<Button
													onClick={() => {
														// saveProjectAccessDetails(item);
													}}
													px={5}
													fontSize="sm"
													backgroundColor={colors.white[900]}
													color={colors.themeBlue[900]}
													height="1.5rem"
													border="1px solid #007799"
													borderRadius="md"
													mx={3}
												>
													Approve
												</Button>
											</Box>
										</Box>
									)) : <Text fontSize="md" pl={3} >No request received </Text>
								}
							</Box>
						</MenuList> : ""}
				</Menu>
			</Box>
			<Box d="flex" px={2}>
				<Menu>
					<MenuButton
						px={2}
						d={{ xs: "none", md: "inline-block" }}
						backgroundColor="transparent"
					>
						<Box d="flex" alignItems="center">
							<Text color="violet.900" fontSize="lg" fontWeight="bold" pr={2}>
								{user.company_name}
							</Text>
							<IoIosArrowDown color="violet.900" w="10px" />
						</Box>
					</MenuButton>
					<MenuList>
						<MenuItem color="gray.700" as="a" onClick={User.logout} hidden={user.is_admin_loggedin_as_client} >
							Logout
						</MenuItem>
					</MenuList>
				</Menu>
			</Box>
		</HeaderWrapper>
	) : (
		<HeaderWrapper
			d="flex"
			px={3}
			boxShadow="md"
			alignItems="center"
			justifyContent="space-between"
		>
			<Box>
				<Image w={"100%"} src={DGShipping} alt="IRClass" height="55px"/>
			</Box>
		</HeaderWrapper>
	);
};

export default Header;
