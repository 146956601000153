/* eslint-disable no-unused-vars */
import React from "react";
import { Box, Image, Text, Link } from "@chakra-ui/react";
import { useLocation, NavLink as RouterLink } from "react-router-dom";
import styled from "@emotion/styled";
import IrclassLogo from "../../assets/images/logo/irclass_logo.png";
import sidemenuRoutes from "./sidemenuRoutes";
import { colors } from "../../helpers/theme";
import BunkerLogo from "../../assets/images/logo/bunker_logo.png";

const NavLink = styled(Link)`
	z-index: 1;
`;

const NavIcon = styled(Image)`
	width: 30px;
	height: 30px;
	padding: 6px;
`;

const SideMenu = () => {
	const [navItems] = React.useState(sidemenuRoutes || []);
	const location = useLocation();
	const { pathname } = location;
	return (
		<Box
			backgroundColor={colors.white[900]}
			w={{ base: "0", lg: "18%" }}
			boxShadow="lg"
			h="100vh"
			position="fixed"
			borderLeft="2px"
			borderColor={colors.violet[900]}>
			<Box my={3} py={3} pl={6}>
				<RouterLink to="/dashboard">
					<Image src={BunkerLogo} alt="Vector Image" width="90%"/>
				</RouterLink>
			</Box>
			<Box px={2} py={4} h={"72vh"} overflow="scroll" overflowX="hidden">
				{navItems.map((item, i) => (
					<NavLink
						key={i}
						px={2}
						d="flex"
						alignItems="center"
						mb={4}
						as={RouterLink}
						style={{ textDecoration: "none" }}
						to={item.to}
						exact={true}>
						<Box
							d="flex"
							alignItems="center"
							px={2}
							color={pathname === item.to ? "white.900" : "gray.700"}
							backgroundColor={pathname === item.to ? colors.violet[600] : ""}
							w={"100%"}
							borderRadius={"md"}>
							<NavIcon src={pathname === item.to ? item.whiteIcon : item.icon} />
							<Text fontSize="1.2rem" px={2} py={3}>
								{item.name}
							</Text>
						</Box>
					</NavLink>
				))}
			</Box>

			<Box p={3} bottom="10px" display={{ md: "flex" }} justifyContent="left" position="absolute">
				<img src={IrclassLogo} width="100%" />
			</Box>
		</Box >
	);
};

export default SideMenu;